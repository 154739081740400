import { useEffect, useState, useCallback } from 'react';

export const tryParse = value => {
    try {
        return JSON.parse(value);
    } catch {
        return value;
    }
};

export const mockedStorage = {
    setItem: () => {},
    removeItem: () => {},
    getItem: () => {},
};

const useStorage = (storageKey, storage) => {
    if (!global.window) {
        return () => {
            return [null, () => {}, () => {}];
        };
    }

    class StorageChanged extends CustomEvent {
        static eventName = `on${storageKey}StorageChange`;

        constructor(payload) {
            super(StorageChanged.eventName, { detail: payload });
        }
    }

    const writeStorage = (key, value) => {
        try {
            storage.setItem(
                key,
                typeof value === 'object' ? JSON.stringify(value) : `${value}`,
            );
            window.dispatchEvent(new StorageChanged({ key, value }));
        } catch (err) {
            if (
                err instanceof TypeError &&
                err.message.includes('circular structure')
            ) {
                throw new TypeError(
                    'The object that was given to the writeStorage function has circular references.\n' +
                        'For more information, check here: ' +
                        'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value',
                );
            }
            throw err;
        }
    };

    const deleteFromStorage = key => {
        storage.removeItem(key);
        window.dispatchEvent(new StorageChanged({ key, value: '' }));
    };

    return (key, initialValue) => {
        const [localState, updateLocalState] = useState(
            tryParse(storage.getItem(key)),
        );
        const onStorageChange = useCallback(event => {
            if (!event.detail?.key) {
                updateLocalState(null);
                return;
            }
            if (event.detail.key === key) {
                updateLocalState(event.detail.value);
            }
        }, []);

        useEffect(() => {
            // The custom storage event allows us to update our component
            // when a change occurs in localStorage outside of our component
            window.addEventListener(StorageChanged.eventName, e =>
                onStorageChange(e),
            );

            // The storage event only works in the context of other documents (eg. other browser tabs)
            window.addEventListener('storage', e => onStorageChange(e));

            if (initialValue) writeStorage(key, initialValue);

            return () => {
                window.removeEventListener(StorageChanged.eventName, e =>
                    onStorageChange(e),
                );
                window.removeEventListener('storage', e => onStorageChange(e));
            };
        }, []);

        return [
            localState,
            value => writeStorage(key, value),
            () => deleteFromStorage(key),
        ];
    };
};
export default useStorage;
