import React, { Component } from 'react';

class Disqus extends Component {
    componentWillMount() {
        if (typeof document !== 'undefined') {
            const com = document.createElement('script'); // eslint-disable-line
            com.type = 'text/javascript';
            com.async = true;
            com.src = `https://cdn.commento.io/js/commento.js`;
            (
                document.getElementsByTagName('head')[0] ||
                document.getElementsByTagName('body')[0]
            ).appendChild(com); // eslint-disable-line
        }
    }
    render() {
        return <div id="commento" />;
    }
}

export default Disqus;
