import React from 'react';
import PropTypes from 'prop-types';

import PrivacyConsent from '../PrivacyConsent';
import Iframe from './Iframe';
import Placeholder from './Placeholder';

const urlToName = url => {
    const urlWithScheme = url.startsWith('//') ? `https:${url}` : url;
    const hostname = new URL(urlWithScheme).hostname;

    switch (true) {
        case /slideshare\.net/.test(hostname):
            return 'Slideshare';
        case /slides\.com/.test(hostname):
            return 'Slides.com';
        case /speakerdeck\.com/.test(hostname):
            return 'SpeakerDeck';
        case /pages\.awscloud\.com/.test(hostname):
            return 'AWS Cloud';
        default:
            return hostname;
    }
};

const PrivacyAwareIframe = ({ src, options, ...rest }) => {
    const name = urlToName(src);
    const key = name.toLowerCase();
    return (
        <PrivacyConsent
            consentKey={key}
            placeholder={setConsent => (
                <Placeholder
                    from={name}
                    setConsent={setConsent}
                    options={options}
                />
            )}
        >
            {setLoaded => <Iframe src={src} setLoaded={setLoaded} {...rest} />}
        </PrivacyConsent>
    );
};

PrivacyAwareIframe.propTypes = {
    src: PropTypes.string.isRequired,
    options: PropTypes.object,
};

PrivacyAwareIframe.defaultProps = {
    options: {
        align: 'center',
    },
};

export default PrivacyAwareIframe;
