import React from 'react';
import styled from 'styled-components';
import useScript from './useScript';

/**
 * MDX component that includes the react-admin newsletter form.
 */
export const Newsletter = () => {
    useScript('https://sibforms.com/forms/end-form/build/main.js', false);
    return (
        <NewsletterContainer className="sib-form">
            <div id="sib-form-container" className="sib-form-container">
                <ErrorMessage
                    id="error-message"
                    className="sib-form-message-panel"
                >
                    <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
                        <svg
                            viewBox="0 0 512 512"
                            className="sib-icon sib-notification__icon"
                        >
                            <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
                        </svg>
                        <span className="sib-form-message-panel__inner-text">
                            We were unable to confirm your registration.
                        </span>
                    </div>
                </ErrorMessage>
                <SuccessMessage
                    id="success-message"
                    className="sib-form-message-panel"
                >
                    <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
                        <svg
                            viewBox="0 0 512 512"
                            className="sib-icon sib-notification__icon"
                        >
                            <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
                        </svg>
                        <span className="sib-form-message-panel__inner-text">
                            Your registration is confirmed.
                        </span>
                    </div>
                </SuccessMessage>
                <SibContainer
                    id="sib-container"
                    className="sib-container--large sib-container--vertical"
                >
                    <form
                        id="sib-form"
                        method="POST"
                        action="https://33092cf4.sibforms.com/serve/MUIFABjf34aNqwnz7KU6mlZSEw0YlhOv5kLEz6UP3kynRLXrxzAKHGDvqPHwj-OnZbVB0dEqMbu5LAhrnZ8bV2bUHzZWZFI7q-i6bF7kDOtDUHGMudDWVthrXBOss1HqMF6fAMcanmR9xiN7p0NIY8s7etKZqdR0M168-K6MpQcGX809AJTHwj10wGJdNjFc0lTDtMxXFpg_7umf"
                        data-type="subscription"
                    >
                        <Box>
                            <FormBlock className="sib-form-block">
                                <p>Keep up to date</p>
                            </FormBlock>
                        </Box>
                        <Box>
                            <FormBlock className="sib-form-block">
                                <div className="sib-text-form-block">
                                    <p>
                                        Join our react-admin newsletter for
                                        regular updates. No spam ever.
                                    </p>
                                </div>
                            </FormBlock>
                        </Box>
                        <Box>
                            <div className="sib-input sib-form-block">
                                <div className="form__entry entry_block">
                                    <div className="form__label-row ">
                                        <div className="entry__field">
                                            <input
                                                className="input "
                                                type="text"
                                                id="EMAIL"
                                                name="EMAIL"
                                                autoComplete="off"
                                                placeholder="example@email.com"
                                                data-required="true"
                                                aria-describedby="error-message"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <ErrorEntry
                                        id="error-message"
                                        className="entry__error entry__error--primary"
                                        htmlFor="EMAIL"
                                    ></ErrorEntry>
                                </div>
                            </div>
                        </Box>
                        <Box>
                            <FormBlock className="sib-form-block">
                                <SubmitButton
                                    className="sib-form-block__button sib-form-block__button-with-loader"
                                    form="sib-form"
                                    type="submit"
                                >
                                    <svg
                                        className="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
                                        viewBox="0 0 512 512"
                                    >
                                        <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                                    </svg>
                                    Subscribe
                                </SubmitButton>
                            </FormBlock>
                        </Box>

                        <input
                            type="text"
                            name="email_address_check"
                            defaultValue=""
                            className="input--hidden"
                        />
                        <input type="hidden" name="locale" defaultValue="en" />
                    </form>
                </SibContainer>
            </div>
        </NewsletterContainer>
    );
};

const NewsletterContainer = styled.div`
    text-align: center;
    width: 100%;
    margin: 0 auto !important;

    @media (min-width: 860px) {
        width: 666px;
    }
`;

const ErrorMessage = styled.div`
    font-size: 16px;
    text-align: left;
    font-family: 'Helvetica', sans-serif;
    color: #661d1d;
    background-color: #ffeded;
    border-radius: 3px;
    border-color: #ff4949;
    max-width: 540px;
`;

const SuccessMessage = styled.div`
    font-size: 16px;
    text-align: left;
    font-family: 'Helvetica', sans-serif;
    color: #085229;
    background-color: #e7faf0;
    border-radius: 3px;
    border-color: #13ce66;
    max-width: 540px;
`;

const SibContainer = styled.div`
    text-align: center;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0;
    border-width: 0;
    direction: ltr;
    border-bottom: solid 1px #dcdcdc;
`;

const Box = styled.div`
    padding: 8px 0;
`;

const FormBlock = styled.div`
    font-size: 16px;
    text-align: left;
    font-weight: 700;
    font-family: 'Helvetica', sans-serif;
    color: #3c4858;
    background-color: transparent;
    text-align: left;
`;

const ErrorEntry = styled.label`
    font-size: 16px;
    text-align: left;
    font-family: 'Helvetica', sans-serif;
    color: #661d1d;
    background-color: #ffeded;
    border-radius: 3px;
    border-color: #ff4949;
`;

const SubmitButton = styled.button`
    font-size: 16px;
    text-align: left;
    font-weight: 700;
    font-family: 'Helvetica', sans-serif;
    color: #ffffff;
    background-color: #3e4857;
    border-radius: 3px;
    border-width: 0px;
`;
