import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import useScript from '../useScript';

const Tweet = ({ id, options, setLoaded }) => {
    const divRef = useRef(null);
    const [tweetLoaded, setTweetLoaded] = useState(false);
    const [twitterWidgetsLoaded] = useScript(
        'https://platform.twitter.com/widgets.js',
    );

    const isReady = () =>
        twitterWidgetsLoaded && window.twttr && window.twttr.widgets;

    useEffect(() => {
        if (tweetLoaded) {
            return;
        }

        if (!isReady()) {
            return;
        }

        window.twttr.widgets
            .createTweet(id, divRef.current, options)
            .then(() => {
                setTweetLoaded(false);
                setLoaded(true);
            });
    }, [twitterWidgetsLoaded]);

    return <div ref={divRef} />;
};

Tweet.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.object,
    setLoaded: PropTypes.func.isRequired,
};

Tweet.defaultProps = {
    options: {},
};

export default Tweet;
