import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Video from '../Placeholder/Video';
import Button from '../Placeholder/Button';

const alignToFlex = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

const Container = styled.div`
    display: flex;
    justify-content: ${({ options }) => alignToFlex[options.align]};
    color: #1c2022;
    fill: ${({ theme }) => theme.grayDark};
`;

const Placeholder = ({ from, setConsent, options }) => (
    <Container options={options}>
        <Video>
            <div>
                <p>
                    {from} might track you and we would rather have your consent
                    before loading this.
                </p>
                <Button onClick={() => setConsent(true)}>Yes, load it.</Button>
            </div>
        </Video>
    </Container>
);

Placeholder.propTypes = {
    from: PropTypes.string,
    setConsent: PropTypes.func.isRequired,
    options: PropTypes.object,
};

Placeholder.defaultProps = {
    from: '',
    options: {},
};

export default Placeholder;
