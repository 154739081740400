import React from 'react';
import PropTypes from 'react-proptypes';
import { translate } from 'react-polyglot';
import { FaTwitter as IconTwitter } from 'react-icons/fa';

import ShareLink from './ShareLink';

const buildTwitterShareUrl = ({ text, url }) =>
    `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        text,
    )}&url=${encodeURIComponent(url)}`;

const ShareTwitter = ({ t, text, url, size }) => {
    const label = t('share.twitter');
    const href = buildTwitterShareUrl({
        text: `${text} - Marmelab`,
        url,
    });

    return (
        <ShareLink label={label} href={href}>
            <IconTwitter color="#4caaea" size={size} />
        </ShareLink>
    );
};

ShareTwitter.propTypes = {
    t: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    size: PropTypes.number,
};

ShareTwitter.defaultProps = {
    size: 30,
};

export default translate()(ShareTwitter);
