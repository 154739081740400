import styled from 'styled-components';

export default styled.div`
    background-color: rgb(205, 205, 205);
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
    > div {
        position: absolute;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 10%;
        text-align: center;
    }
    @media (max-width: 600px) {
        padding-bottom: 0;
        > div {
            position: relative;
            height: 220px;
        }
        font-size: 0.8rem;
    }
`;
