import styled from 'styled-components';

export default styled.div`
    background-color: rgb(205, 205, 205);
    border-radius: 4px;
    margin-top: 10.4px;
    width: 465px;
    height: 317px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.headlinesColor};
    font-family: 'Open Sans', sans-serif;
    text-align: center;

    @media (max-width: 600px) {
        width: auto;
        height: 220px;
        font-size: 0.8rem;
    }
`;
