import React from 'react';
import PropTypes from 'react-proptypes';
import { I18n } from 'react-polyglot';
import { graphql } from 'gatsby';

import messages from '../../content/en';

import Layout from '../components/layout';
import BlogPostPage from '../components/BlogPostPage';

const Template = ({ data }) => {
    return (
        <Layout>
            <I18n locale="en" messages={messages}>
                <BlogPostPage locale="en" data={data} />
            </I18n>
        </Layout>
    );
};

Template.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default Template;

export const pageQuery = graphql`
    query BlogPostByPath($id: String!) {
        site {
            siteMetadata {
                siteUrl
            }
        }

        rawPost: mdx(id: { eq: $id }) {
            body
            date
            path
            frontmatter {
                excerpt
                authors
                title
                cover_image {
                    publicURL
                    childImageSharp {
                        fluid(maxWidth: 1280, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                thumbnail_image {
                    publicURL
                }
                tags
                canonical
            }
        }
        team: allMdx(filter: { fileRelativePath: { glob: "**/team/**" } }) {
            edges {
                node {
                    id
                    frontmatter {
                        name
                        fullname
                        link
                        github
                        twitter
                        picture
                        picture_sp
                        biography
                    }
                }
            }
        }
    }
`;
