import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const Asciinema = ({ id, setLoaded }) => {
    const divRef = useRef(null);
    let script = null;

    const onScriptLoad = () => {
        setLoaded(true);
        script.removeEventListener('load', onScriptLoad);
    };

    useEffect(() => {
        if (!divRef.current.children.length) {
            script = document.createElement('script');

            script.id = `asciicast-${id}`;
            script.src = `https://asciinema.org/a/${id}.js`;
            script.async = true;
            script.addEventListener('load', onScriptLoad);
            divRef.current.appendChild(script);
        }
    }, [divRef.current]);

    return <div ref={divRef} />;
};

Asciinema.propTypes = {
    id: PropTypes.string.isRequired,
    setLoaded: PropTypes.func.isRequired,
};

export default Asciinema;
