import React from 'react';
import PropTypes from 'react-proptypes';
import styled from 'styled-components';
import Media from 'react-media';
import {
    FaTwitter as IconTwitter,
    FaGithub as IconGithub,
} from 'react-icons/fa';

import { translate } from 'react-polyglot';

const Container = styled.div`
    background-color: white;
    padding: 20px;
    margin: 10px 0;
    @media (max-width: 599px) {
        margin: 10px;
    }
`;

const PictureContainer = styled.div`
    @media (min-width: 600px) {
        margin: 10px 0 0 0;
    }
    min-width: unset;
`;

const AuthorPicture = styled.img`
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
    @media (max-width: 599px) {
        height: 3.125rem;
        width: 3.125rem;
    }
`;

const FlexContainer = styled.div`
    display: flex;
`;

const TextContainer = styled.div`
    padding-left: 20px;
`;

const FullnameContainer = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 22px;
    margin: 10px 0;
    color: ${({ theme }) => theme.blueDark};
    text-decoration: none;
`;

const BiographyContainer = styled.p`
    font-size: 14.4px;
    margin: 10px 0;
`;

const TwitterLinkContainer = styled.a`
    color: #1ea1f1;
    margin: 5px;
`;

const GithubLinkContainer = styled.a`
    margin: 5px;
    color: #333333;
    > svg : {
        color: #333333;
    }
`;

const LinkContainer = styled.div`
    float: right;
`;

const Link = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.blueDark};
`;

const AuthorCard = ({ author, t }) => (
    <Container>
        <Media query="(max-width: 600px)">
            {matches =>
                matches ? (
                    <div>
                        <FlexContainer>
                            <PictureContainer>
                                <AuthorPicture
                                    alt={author.fullname}
                                    src={author.picture}
                                />
                            </PictureContainer>
                            <TextContainer>
                                <FullnameContainer>
                                    <Link href={author.link}>
                                        {author.fullname}
                                    </Link>
                                </FullnameContainer>
                            </TextContainer>
                        </FlexContainer>
                        <BiographyContainer>
                            {author.biography}
                        </BiographyContainer>
                        <LinkContainer>
                            {author.twitter && (
                                <TwitterLinkContainer>
                                    <IconTwitter size={24} />
                                </TwitterLinkContainer>
                            )}
                            {author.github && (
                                <GithubLinkContainer>
                                    <IconGithub size={24} />
                                </GithubLinkContainer>
                            )}
                        </LinkContainer>
                    </div>
                ) : (
                    <div>
                        <FlexContainer>
                            <PictureContainer>
                                <AuthorPicture
                                    alt={author.fullname}
                                    src={author.picture}
                                />
                            </PictureContainer>
                            <TextContainer>
                                <FullnameContainer>
                                    <Link href={author.link}>
                                        {author.fullname}
                                    </Link>
                                </FullnameContainer>
                                <BiographyContainer>
                                    {author.biography}
                                </BiographyContainer>
                            </TextContainer>
                        </FlexContainer>
                        <LinkContainer>
                            {author.twitter && (
                                <TwitterLinkContainer
                                    aria-label={t('author.twitter', {
                                        author: author.fullname,
                                    })}
                                    href={author.link}
                                >
                                    <IconTwitter size={24} />
                                </TwitterLinkContainer>
                            )}
                            {author.github && (
                                <GithubLinkContainer
                                    aria-label={t('author.github', {
                                        author: author.fullname,
                                    })}
                                    href={author.github}
                                >
                                    <IconGithub size={24} />
                                </GithubLinkContainer>
                            )}
                        </LinkContainer>
                    </div>
                )
            }
        </Media>
    </Container>
);

AuthorCard.propTypes = {
    author: PropTypes.shape({
        fullname: PropTypes.string.isRequired,
        biography: PropTypes.string.isRequired,
        picture: PropTypes.string.isRequired,
        twitter: PropTypes.string,
        link: PropTypes.string,
        github: PropTypes.string,
    }),
    t: PropTypes.func,
};

export default translate()(AuthorCard);
