import React from 'react';
import PropTypes from 'react-proptypes';
import styled from 'styled-components';

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 10px;
`;

const ShareLink = ({ children, label, href }) => (
    <a
        aria-label={label}
        title={label}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
    >
        <IconContainer>{children}</IconContainer>
    </a>
);

ShareLink.propTypes = {
    children: PropTypes.element,
    label: PropTypes.string,
    href: PropTypes.string,
};

export default ShareLink;
