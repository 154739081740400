import React from 'react';
import PropTypes from 'prop-types';
import useScript from './useScript';

/**
 * MDX component that embeds a codepen
 */
export const Codepen = ({ slug, user, title, height = 300 }) => {
    useScript('https://cpwebassets.codepen.io/assets/embed/ei.js', false);
    return (
        <p
            className="codepen"
            data-height={height}
            data-default-tab="result"
            data-slug-hash={slug}
            data-user={user}
            style={{
                height: `${height}px`,
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '2px solid',
                margin: '1em 0',
                padding: '1em',
            }}
        >
            <span>
                See the Pen{' '}
                <a href={`https://codepen.io/${user}/pen/${slug}`}>{title}</a>{' '}
                by <a href={`https://codepen.io/${user}`}>@{user}</a> on{' '}
                <a href="https://codepen.io">CodePen</a>.
            </span>
        </p>
    );
};

Codepen.propTypes = {
    slug: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    height: PropTypes.number,
};

Codepen.defaultProps = {
    height: 300,
};
