import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import useScript from '../useScript';

const Youtube = ({ id, options, setLoaded }) => {
    const divRef = useRef(null);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [youtubeScriptLoaded] = useScript(
        'https://s.ytimg.com/yts/jsbin/www-widgetapi-vfl6mioSm/www-widgetapi.js',
    );

    const isReady = () => youtubeScriptLoaded && window.YT && window.YT.Player;

    useEffect(() => {
        if (videoLoaded) {
            return;
        }

        if (!isReady()) {
            return;
        }

        const youtubeOptions = {
            videoId: id,
            ...options,
            events: {
                onReady: () => {
                    setVideoLoaded(true);
                    setLoaded(true);
                },
            },
        };

        new window.YT.Player(divRef.current, youtubeOptions);
    }, [youtubeScriptLoaded, isReady()]);

    return <div ref={divRef} />;
};

Youtube.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.object,
    setLoaded: PropTypes.func.isRequired,
};

Youtube.defaultProps = {
    options: {},
};

export default Youtube;
