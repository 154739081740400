import React from 'react';
import PropTypes from 'prop-types';

import PrivacyConsent from '../PrivacyConsent';
import Tweet from './Tweet';
import Placeholder from './Placeholder';

const PrivacyAwareTweet = ({ id, options }) => {
    return (
        <PrivacyConsent
            consentKey="tweet"
            placeholder={setConsent => (
                <Placeholder
                    id={id}
                    setConsent={setConsent}
                    options={options}
                />
            )}
        >
            {setLoaded => (
                <Tweet id={id} options={options} setLoaded={setLoaded} />
            )}
        </PrivacyConsent>
    );
};

PrivacyAwareTweet.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.object,
};

PrivacyAwareTweet.defaultProps = {
    options: {
        align: 'center',
    },
};

export default PrivacyAwareTweet;
