import styled from 'styled-components';

export default styled.button`
    background-color: ${({ theme }) => theme.blue};
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8125rem;
    font-weight: bold;
    letter-spacing: 2px;
    padding: 0.6875rem 2rem;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    @media (max-width: 600px) {
        font-size: 0.6rem;
    }

    &:hover {
        background-color: ${({ theme }) => theme.blueDark};
    }

    display: block;
    color: ${({ theme }) => theme.white} !important;
    border-radius: 20px;
    border: 0;
`;
