import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const BlogTagButton = styled.button`
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.grayDarker};
    margin: 0 0.5rem 0 0;
    font-style: italic;
    font-size: 0.9rem;
    white-space: nowrap;
`;

export const BlogTagText = styled.span`
    color: ${({ theme }) => theme.grayDarker};
    margin: 0 0.5rem 0 0;
    font-style: italic;
    font-size: 0.9rem;
    white-space: nowrap;
`;

export default class BlogTag extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        tag: PropTypes.string.isRequired,
    };

    handleClick = () => {
        this.props.onClick(this.props.tag);
    };

    render() {
        const { onClick, tag } = this.props;
        if (onClick) {
            return (
                <BlogTagButton onClick={this.handleClick}>#{tag}</BlogTagButton>
            );
        }

        return <BlogTagText>#{tag}</BlogTagText>;
    }
}
