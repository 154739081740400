import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Video from '../Placeholder/Video';
import Button from '../Placeholder/Button';

const alignToFlex = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};

const Container = styled.div`
    display: flex;
    justify-content: ${({ options }) => alignToFlex[options.align]};
    color: #1c2022;
    fill: ${({ theme }) => theme.grayDark};
`;

const PlayIcon = props => (
    <svg viewBox="0 0 32 32" {...props}>
        <path d="M12.247 9.357v13.286L23.753 16z" />
        <path d="M16 32c8.822 0 16-7.178 16-16S24.823 0 16 0 0 7.178 0 16s7.178 16 16 16zm0-30c7.72 0 14 6.28 14 14s-6.28 14-14 14S2 23.72 2 16 8.281 2 16 2z" />
    </svg>
);

const Placeholder = ({ setConsent, options }) => (
    <Container options={options}>
        <Video>
            <div>
                <PlayIcon width={120} />
                <p>
                    Vimeo might track you and we would rather have your consent
                    before loading this video.
                </p>
                <Button onClick={() => setConsent(true)}>Yes, load it.</Button>
            </div>
        </Video>
    </Container>
);

Placeholder.propTypes = {
    setConsent: PropTypes.func.isRequired,
    options: PropTypes.object,
};

Placeholder.defaultProps = {
    options: {},
};

export default Placeholder;
