import React from 'react';
import PropTypes from 'prop-types';

const Iframe = ({ src, setLoaded, ...rest }) => {
    return (
        <iframe
            src={src}
            onLoad={() => setLoaded(true)}
            width="425"
            height="355"
            {...rest}
        />
    );
};

Iframe.propTypes = {
    src: PropTypes.string.isRequired,
    setLoaded: PropTypes.func.isRequired,
};

export default Iframe;
