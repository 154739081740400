import React from 'react';
import PropTypes from 'react-proptypes';
import styled from 'styled-components';

const Button = styled.button`
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 10px;
`;

const ShareButton = ({ children, label, onClick }) => (
    <Button aria-label={label} title={label} onClick={onClick}>
        <IconContainer>{children}</IconContainer>
    </Button>
);

ShareButton.propTypes = {
    children: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

ShareButton.defaultProps = {
    size: 30,
};

export default ShareButton;
