import React from 'react';
import PropTypes from 'react-proptypes';
import styled from 'styled-components';
import { translate } from 'react-polyglot';

import ShareFacebook from '../Share/ShareFacebook';
import ShareTwitter from '../Share/ShareTwitter';
import ShareLinkedIn from '../Share/ShareLinkedIn';
import ShareMore from '../Share/ShareMore';

const ShareContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 1.5rem 0;
    @media (max-width: 740px) {
        justify-content: center;
        padding: 0 10px;
    }
`;

const ShareLinks = styled.div`
    display: flex;
    margin: 0 0.5rem;
`;

const ShareText = styled.div`
    display: flex;
    margin: 0 0.5rem;
`;

const getUrl = post => post.canonical || `https://marmelab.com${post.path}`;

const SharePost = ({ t, post }) => {
    const url = getUrl(post);
    return (
        <ShareContainer>
            <ShareText>{t('share.text')}</ShareText>
            <ShareLinks>
                <ShareFacebook url={url} />
                <ShareTwitter text={post.title} url={url} />
                <ShareLinkedIn url={url} />
                <ShareMore title={post.title} url={url} />
            </ShareLinks>
        </ShareContainer>
    );
};

SharePost.propTypes = {
    t: PropTypes.func,
    post: PropTypes.object,
};

export default translate()(SharePost);
