import React, { Component } from 'react';
import PropTypes from 'react-proptypes';
import styled from 'styled-components';
import format from 'date-fns/format';
import { enGB as enLocale, fr as frLocale } from 'date-fns/locale';
import withLocale from '../i18n/withLocale';

const AuthorshipContainer = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-bottom: 0;
    @media (max-width: 1280px) {
        flex-wrap: wrap;
    }
`;

export const AuthorPicture = styled.img`
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    flex-shrink: 0;
    margin-top: 7px;
    margin-bottom: 7px;
    &:not(:first-child) {
        margin-left: -7px;
    }
    &:last-of-type {
        margin-right: 1rem;
    }
`;

const AuthorshipText = styled.div`
    display: flex;
    flex-direction: ${({ isSingleLine }) => (isSingleLine ? 'row' : 'column')};
    font-size: 0.8125rem;
    line-height: 1.125rem;
`;

class AuthorName extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        name: PropTypes.string.isRequired,
        fullname: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    };

    handleClick = () => {
        this.props.onClick(this.props.name);
    };

    render() {
        const { onClick, fullname, link } = this.props;

        if (onClick) {
            return (
                <AuthorButton onClick={this.handleClick}>
                    {fullname}
                </AuthorButton>
            );
        }

        return <AuthorLink href={link}>{fullname}</AuthorLink>;
    }
}

const RawAuthorNames = ({
    authors,
    onAuthorClick,
    linkToAuthorBlogPages,
    locale,
}) => (
    <span>
        {interpolate(
            authors.map(author => {
                const { name } = author;
                return (
                    <AuthorName
                        key={name}
                        onClick={onAuthorClick}
                        {...author}
                        link={
                            linkToAuthorBlogPages
                                ? `/${locale}/blog/authors/${name}`
                                : author.link
                        }
                    />
                );
            }),
            ' & ',
        )}
    </span>
);

RawAuthorNames.propTypes = {
    onAuthorClick: PropTypes.func,
    linkToAuthorBlogPages: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    authors: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export const AuthorNames = withLocale(RawAuthorNames);

AuthorNames.propTypes = {
    onAuthorClick: PropTypes.func,
    linkToAuthorBlogPages: PropTypes.bool,
    authors: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const AuthorLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.orange};
    font-weight: 600;
`;

const AuthorButton = styled.button`
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    text-decoration: none;
    color: ${({ theme }) => theme.orange};
    font-weight: 600;
    padding: 0;
`;

const interpolate = (array, separator) => {
    return array.reduce(
        (acc, author, index, array) =>
            index < array.length - 1
                ? [...acc, author, separator]
                : [...acc, author],
        [],
    );
};

const translateDate = {
    en: date => format(new Date(date), 'MMMM dd, yyyy', { locale: enLocale }),
    fr: date => format(new Date(date), 'dd MMMM yyyy', { locale: frLocale }),
};

const AuthorshipLightContainer = styled.div`
    margin: 0.5rem 0;
`;

export const AuthorshipLight = withLocale(
    ({
        date,
        authors,
        className,
        locale,
        onAuthorClick,
        linkToAuthorBlogPages,
    }) => (
        <AuthorshipLightContainer className={className}>
            <span>{translateDate[locale](date)}</span>
            <span>{' by '}</span>
            <AuthorNames
                authors={authors}
                onAuthorClick={onAuthorClick}
                linkToAuthorBlogPages={linkToAuthorBlogPages}
            />
        </AuthorshipLightContainer>
    ),
);

AuthorshipLight.propTypes = {
    onAuthorClick: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
    authors: PropTypes.arrayOf(PropTypes.object.isRequired),
    locale: PropTypes.string.isRequired,
};

const Authorship = ({
    date,
    authors,
    locale,
    onAuthorClick,
    linkToAuthorBlogPages,
}) => (
    <AuthorshipContainer>
        {authors.map(author => {
            const { picture } = author;
            return (
                <AuthorPicture
                    key={author.fullname}
                    alt={author.fullname}
                    src={picture}
                />
            );
        })}
        <AuthorshipText>
            <AuthorNames
                authors={authors}
                onAuthorClick={onAuthorClick}
                linkToAuthorBlogPages={linkToAuthorBlogPages}
            />
            <span>{translateDate[locale](date)}</span>
        </AuthorshipText>
    </AuthorshipContainer>
);

Authorship.propTypes = {
    onAuthorClick: PropTypes.func,
    linkToAuthorBlogPages: PropTypes.bool,
    date: PropTypes.string.isRequired,
    authors: PropTypes.arrayOf(PropTypes.object.isRequired),
    locale: PropTypes.string.isRequired,
};

export default withLocale(Authorship);
