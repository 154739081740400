import Tweet from './Tweet';
import Vimeo from './Vimeo';
import Youtube from './Youtube';
import Iframe from './Iframe';
import Asciinema from './Asciinema';
import { Newsletter } from './Newsletter';
import { Codepen } from './Codepen';

export default {
    Tweet,
    Vimeo,
    Youtube,
    Iframe,
    Asciinema,
    Newsletter,
    Codepen,
};
