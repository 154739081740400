import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BlogTag from './BlogTag';

const BlogTagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0;
`;

const BlogTagList = ({ className, tags, onTagClick }) => (
    <BlogTagsContainer className={className}>
        {tags &&
            tags.map(tag => (
                <BlogTag key={tag} onClick={onTagClick} tag={tag} />
            ))}
    </BlogTagsContainer>
);

BlogTagList.propTypes = {
    className: PropTypes.string,
    onTagClick: PropTypes.func,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BlogTagList;
