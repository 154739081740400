import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useStorage, { mockedStorage } from './useStorage';

let sessionStorage = mockedStorage;
let localStorage = mockedStorage;

if (global.window) {
    sessionStorage = window.sessionStorage;
    localStorage = window.localStorage;
}

const useSessionStorage = useStorage('session', sessionStorage);
const useLocalStorage = useStorage('local', localStorage);

const Container = styled.div`
    width: 100%;
    overflow: hidden;
`;

const SlideContainer = styled.div`
    width: 200%;
    display: flex;

    .loaded & {
        margin-left: -100%;
    }
`;

const NotLoadedContainer = styled.div`
    width: 50%;
`;

const LoadedContainer = styled.div`
    width: 50%;
`;

const Link = styled.a`
    text-align: center;
    display: block;
    font-size: 12px;
    font-style: italic;
`;

const AppearContainer = styled.div`
    transition: all 0.3s ease-in-out;
`;

const PrivacyConsent = ({ consentKey, placeholder, children }) => {
    const [loaded, setLoaded] = useState(false);
    const [consent, setConsent] = useSessionStorage(consentKey, false);
    const [permanentConsent, setPermanentConsent] = useLocalStorage(
        consentKey,
        false,
    );

    const alwaysAllow = event => {
        event.preventDefault();
        setPermanentConsent(true);
    };

    const resetSettings = event => {
        event.preventDefault();
        setLoaded(false);
        setConsent(false);
        setPermanentConsent(false);
    };

    const shouldShow = consent || permanentConsent;
    if (!shouldShow && loaded) {
        setLoaded(false);
    }

    return (
        <Container className={loaded ? 'loaded' : ''}>
            <SlideContainer>
                <NotLoadedContainer>
                    {placeholder(setConsent)}
                    <Link href="#" onClick={alwaysAllow}>
                        Always allow
                    </Link>
                </NotLoadedContainer>
                <LoadedContainer>
                    {shouldShow && (
                        <AppearContainer>
                            {children(setLoaded)}
                            <Link href="#" onClick={resetSettings}>
                                Reset privacy settings
                            </Link>
                        </AppearContainer>
                    )}
                </LoadedContainer>
            </SlideContainer>
        </Container>
    );
};

PrivacyConsent.propTypes = {
    consentKey: PropTypes.string.isRequired,
    placeholder: PropTypes.node,
    children: PropTypes.node,
};

export default PrivacyConsent;
