import React from 'react';
import PropTypes from 'react-proptypes';
import { translate } from 'react-polyglot';
import { FaLinkedin as IconLinkedIn } from 'react-icons/fa';

import ShareLink from './ShareLink';

const buildLinkedinShareUrl = ({ url }) =>
    `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url,
    )}`;

const ShareLinkedin = ({ t, url, size }) => {
    const label = t('share.linkedIn');
    const href = buildLinkedinShareUrl({ url });

    return (
        <ShareLink label={label} href={href}>
            <IconLinkedIn color="#2867b2" size={size} />
        </ShareLink>
    );
};

ShareLinkedin.propTypes = {
    t: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    size: PropTypes.number,
};

ShareLinkedin.defaultProps = {
    size: 30,
};

export default translate()(ShareLinkedin);
