import React from 'react';
import PropTypes from 'react-proptypes';
import { translate } from 'react-polyglot';
import { IoMdShare as IconShare } from 'react-icons/io';

import ShareButton from './ShareButton';

const isServerSide = () => typeof window === 'undefined';
const isShareFeatureEnabled = () => navigator && navigator.share;

const ShareMore = ({ t, url, title, size }) => {
    if (isServerSide() || !isShareFeatureEnabled()) {
        return null;
    }

    const label = t('share.more');

    const share = async () => {
        await navigator.share({
            title,
            url,
        });
    };

    return (
        <ShareButton label={label} onClick={share}>
            <IconShare color="#000" size={size} />
        </ShareButton>
    );
};

ShareMore.propTypes = {
    t: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.number,
};

ShareMore.defaultProps = {
    size: 30,
};

export default translate()(ShareMore);
