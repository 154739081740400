import React from 'react';
import PropTypes from 'react-proptypes';
import { translate } from 'react-polyglot';
import { FaFacebook as IconFacebook } from 'react-icons/fa';

import ShareLink from './ShareLink';

const buildFacebookShareUrl = ({ url }) =>
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;

const ShareFacebook = ({ t, url, size }) => {
    const label = t('share.facebook');
    const href = buildFacebookShareUrl({ url });

    return (
        <ShareLink label={label} href={href}>
            <IconFacebook color="#4267b2" size={size} />
        </ShareLink>
    );
};

ShareFacebook.propTypes = {
    t: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    size: PropTypes.number,
};

ShareFacebook.defaultProps = {
    size: 30,
};

export default translate()(ShareFacebook);
