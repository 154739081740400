import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import useScript from '../useScript';

const Vimeo = ({ id, options, setLoaded }) => {
    const divRef = useRef(null);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [vimeoScriptLoaded] = useScript(
        'https://player.vimeo.com/api/player.js',
    );

    const isReady = () =>
        vimeoScriptLoaded && window.Vimeo && window.Vimeo.Player;

    useEffect(() => {
        if (videoLoaded) {
            return;
        }

        if (!isReady()) {
            return;
        }

        const vimeoOptions = {
            url: `https://vimeo.com/${id}`,
            ...options,
        };

        const videoPlayer = new window.Vimeo.Player(
            divRef.current,
            vimeoOptions,
        );

        videoPlayer.on('loaded', () => {
            setVideoLoaded(true);
            setLoaded(true);
        });
    }, [vimeoScriptLoaded]);

    return <div ref={divRef} />;
};

Vimeo.propTypes = {
    id: PropTypes.string.isRequired,
    setLoaded: PropTypes.func.isRequired,
    options: PropTypes.object,
};

Vimeo.defaultProps = {
    options: {},
};

export default Vimeo;
